import { render, staticRenderFns } from "./appraisalQuestionBankAdd.vue?vue&type=template&id=873f106c&scoped=true"
import script from "./appraisalQuestionBankAdd.vue?vue&type=script&lang=js"
export * from "./appraisalQuestionBankAdd.vue?vue&type=script&lang=js"
import style0 from "./appraisalQuestionBankAdd.vue?vue&type=style&index=0&id=873f106c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "873f106c",
  null
  
)

export default component.exports