<template>
  <el-dialog
    title="基本设置"
    :visible.sync="dialogFormVisible"
    width="65%"
    top="1%"
    @close="doClose"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <h3 class="h3">基本信息</h3>
      <el-form-item
        label="题库名称："
        label-width="100px"
        prop="questionBankName"
      >
        <el-input
          v-model="ruleForm.questionBankName"
          placeholder="请输入题库名称"
          maxlength="50"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="题库类别：" label-width="100px" prop="categoryCode">
         <el-cascader
          v-model="ruleForm.categoryCode"
          :options="TreeList"
          :props="propsTree"
          :disabled="disabled"
          clearable
          filterable
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item>
        <div class="flexcc">
          <el-button @click="doClose()">取 消</el-button>
          <el-button class="bgc-bv" type="primary" @click="doOk"
            >确 定</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  name: "questionbankInformation",
  components: {
  },
  data() {
    return {
      dialogFormVisible: false,
  
      //基本设置
      ruleForm: {
        questionBankName: "", //题库名称
        categoryCode: "",
      },
      TreeList: [],
propsTree: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: false,
      },
      questionBankId: "",
      rules: {
        questionBankName: [
          {
            required: true,
            message: "请输入题库名称长度最多50字",
            trigger: "blur",
          },
        ],
        categoryCode: [
          { required: true, message: "请选择题库类别", trigger: "change" },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.getTree()
  },
  methods: {
   
    showPopUp(questionBankId) {
      this.dialogFormVisible = true;
      this.questionBankId = questionBankId;
      if (questionBankId && questionBankId != undefined) {
        this.getInformation(questionBankId);
      }
    },
 /* 题库类别 */
    getTree() {
      this.$post('/miniapp/category/enable/tree',{disabledLevels:'1,2,3'},3000, true, 2).then(ret => {
        this.TreeList = ret.data
      })
    },
    /* 基本信息回显数据 */
    getInformation(questionBankId) {
      this.$post("/evaluate/question/bank/query", { questionBankId }, 3000, false, 2)
        .then((ret) => {
          let retData = ret.data;
          this.ruleForm.questionBankName = retData.questionBankName;
           this.ruleForm.categoryCode = retData.categoryCode;
        
        })
        .catch((err) => {
          return;
        });
    },
    doOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.showtitle = false;
          const that = this;
          let parmar = {
            questionBankName: that.ruleForm.questionBankName,
            categoryCode: that.ruleForm.categoryCode,
          };
           if (that.questionBankId) {
            parmar.questionBankId = that.questionBankId;
          }
          that
            .$post("/evaluate/question/bank/save", parmar, 3000, true, 2)
            .then((ret) => {
              that.doClose();
              // that.questionBankId = ret.data
              // that.$emit("getnewdatap");
              // that.$emit("subShows", true);
              that.parentPsBack(ret.data);
              if (ret.status == 0) {
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: ret.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    doClose() {
      this.$refs["ruleForm"].resetFields();
      this.dialogFormVisible = false;
      this.ruleForm = {
        questionBankName: "", //题库名称
        categoryCode: "",
      };
    },
    // 给父组件传参
    parentPsBack(id) {
      this.$emit("eventBus", id);
      this.doClose();
    },
  },
};
</script>
