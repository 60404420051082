<template>
  <!-- 高级搜索 -->
  <el-dialog
    :visible.sync="centerDialogVisible"
    width="65%"
    top="0.5%"
    center
    @close="closeDialog"
    title="新增试题"
    :close-on-click-modal="false"
  >
    <div style="height: 600px">
      <div class="ovy-a">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="添加试题" name="first"></el-tab-pane>
          <!-- <el-tab-pane label="从题库添加试题" name="second"></el-tab-pane> -->
        </el-tabs>
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="题目类型" required class="el-form-questionType">
            <el-radio-group
              v-model="form.questionType"
              @change="formTypeChange"
            >
              <el-radio :label="0">单选题</el-radio>
              <el-radio :label="1">多选题</el-radio>
              <el-radio :label="2">判断题</el-radio>
              <el-radio :label="3">填空题</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="题目内容" class="areaBox" required>
            <div
              ref="editorsquestionTitle"
              style="width: 100%; position: relative; z-index: 2"
            />
            <span v-show="showquestionTitle" class="showtitle"
              >请输入题目内容</span
            >
          </el-form-item>
          <el-form-item
            label
            v-if="form.questionType == 0"
            prop="questionAnswer"
          >
            <div>
              <el-radio-group v-model="form.questionAnswer">
                <div
                  v-for="(item, index) in form.optionList"
                  :key="index"
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                  "
                >
                  <el-form-item :prop="'optionList[' + index + '].mins'">
                    <el-radio :label="optionFM(index)"
                      >选项{{ optionFM(index) }}</el-radio
                    >
                    <el-input
                      v-model="form.optionList[index].mins"
                      style="width: 220px"
                      maxlength="200"
                      show-word-limit
                    ></el-input>
                    <el-upload
                      :on-change="
                        (res, file) => {
                          return handleAvatarSuccess2(res, file, index);
                        }
                      "
                      :before-upload="$beforeAvatarUpload"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >
                      <el-image
                        :src="
                          form.optionList[index].img ||
                          require('@/assets/develop.png')
                        "
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                    </el-upload>
                    <el-button
                      v-if="index > 1"
                      class="delet"
                      type="text"
                      @click="delOpenList(index)"
                      >删除选项</el-button
                    >
                    <el-button
                      v-if="form.questionAnswer == optionFM(index)"
                      class="delet"
                      type="text"
                      >正确答案</el-button
                    >
                  </el-form-item>
                </div>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label
            v-if="form.questionType == 1"
            prop="questionAnswer2"
          >
            <div>
              <!-- <el-checkbox-group v-model="form.questionAnswer2"> -->
              <div
                v-for="(item, index) in form.optionListall"
                :key="index"
                style="display: flex; align-items: center; margin-bottom: 18px"
              >
                <el-form-item
                  :prop="'optionListall[' + index + '].optionContent'"
                  class="checkbox"
                >
                  <el-checkbox
                    v-model="form.optionListall[index].optionIsCorrect"
                    :label="optionFM(index)"
                    >选项{{ optionFM(index) }}
                    <el-input
                      v-model="form.optionListall[index].optionContent"
                      style="width: 220px"
                      maxlength="200"
                      show-word-limit
                    ></el-input>
                  </el-checkbox>
                  <el-upload
                    :on-change="
                      (res, file) => {
                        return handleAvatarSuccess3(res, file, index);
                      }
                    "
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        form.optionListall[index].optionSpecialContent ||
                        require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                  <el-button
                    v-if="form.questionAnswer2.indexOf(optionFM(index)) !== -1"
                    type="text"
                    class="delet"
                    >正确答案</el-button
                  >
                  <el-button
                    v-if="index > 2"
                    type="text"
                    class="delet"
                    @click="delOpenList(index)"
                    >删除</el-button
                  >
                </el-form-item>
              </div>
              <!-- </el-checkbox-group> -->
            </div>
          </el-form-item>
          <el-form-item
            label="正确答案"
            v-if="form.questionType == 2"
            prop="questionAnswer"
          >
            <div>
              <el-radio-group v-model="form.questionAnswer">
                <el-radio label="T" @change="debloChangeList(index)"
                  >正确</el-radio
                >
                <el-radio label="F" @change="debloChangeList(index)"
                  >错误</el-radio
                >
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label="正确答案"
            v-if="form.questionType == 3"
            prop="questionAnswer"
          >
            <div>
              <el-input
                type="textarea"
                :rows="3"
                placeholder="请输入正确答案"
                v-model="form.questionAnswer"
                style="width: 360px"
                maxlength="200"
                show-word-limit
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item
            label
            v-if="
              (form.questionType == 0 || form.questionType == 1) &&
              form.optionList.length < 6 &&
              form.optionListall.length < 6
            "
          >
            <el-button @click="addFromOpenList">添加选项</el-button>
          </el-form-item>
          <el-form-item label="题目解析" required>
            <div
              ref="editors"
              style="width: 100%; position: relative; z-index: 2"
            />
            <span v-show="showtitle" class="showtitle">请输入答案解析</span>
          </el-form-item>
          <el-form-item label="试题来源" prop="questionOrigin">
            <el-select
              size="small"
              v-model="form.questionOrigin"
              placeholder="请选择试题来源"
              clearable
            >
              <el-option
                v-for="item in QuestionList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="bgc-bv" @click="docancle()">取 消</el-button>
          <el-button class="bgc-bv" @click="addOptions">确 定</el-button>
        </span>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import E from "wangeditor";

let editor = {};
let editors = {};
export default {
  name: "questionPaperPop",
  components: {
    // Empty,
  },
  mixins: [List],
  data() {
    return {
      letterArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "X",
        "Y",
        "Z",
      ],
      activeName: "first",
      showtitle: false,
      showquestionTitle: false,
      centerDialogVisible: false,
      QuestionList: [],
      form: {
        questionType: 0,
        optionList: [
          { mins: "", img: "" },
          { mins: "", img: "" },
        ],
        optionListall: [
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
            optionSpecialContentKey: "",
          },
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
            optionSpecialContentKey: "",
          },
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
            optionSpecialContentKey: "",
          },
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
            optionSpecialContentKey: "",
          },
        ],
        questionAnswer: "",
        questionAnswer2: [],
        questionOrigin: "10",
      },
      questionBankId: "",
      ruleForm: {},
      rules: {
        questionAnswer: [
          {
            required: true,
            message: "请选择一个正确答案项",
            trigger: ["blur", "change"],
          },
        ],
        questionOrigin: [
          {
            required: true,
            message: "请选择试题来源",
            trigger: "change",
          },
        ],
        "optionListall[index].optionIsCorrect": [
          {
            required: true,
            message: "请选择至少一个正确答案项",
            trigger: "change",
          },
        ],
        questionOrigin: [
          { required: true, message: "请选择试题来源", trigger: "blur" },
        ],
        "optionList[0].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionList[1].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionList[2].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionList[3].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionList[4].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionList[5].mins": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[0].optionContent": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[1].optionContent": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[2].optionContent": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[3].optionContent": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[4].optionContent": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ],
        "optionListall[5].optionContent": [
          { required: true, message: "请输入答案", trigger: "blur" }
        ]
      },
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {
    editor = {};
  },
  computed: {},
  methods: {
    init() {
      // let arr = Object.keys(editor);
      // if (arr.length == 0) {
      //   this.$nextTick(function() {
      //     this.editorInit();
      //   });
      // }
    },
    /* 试卷来源start */
    getquestionList() {
      const questionlist = this.$setDictionary("QUESTIONORIGIN", "list");
      const list = [];
      for (const key in questionlist) {
        list.push({
          value: key,
          label: questionlist[key],
        });
      }
      this.QuestionList = list;
    },
    /*试卷来源 end */
    showPopUp(questionBankId) {
      const that = this;
      that.questionBankId = questionBankId;
      that.centerDialogVisible = true;
      let arr = Object.keys(editor);
      if (arr.length == 0) {
        that.$nextTick(function () {
          that.editorInit();
          that.editorInit2();
          that.getquestionList();
          that.form.questionAnalysis = editor.txt.html("");
        });
      }
    },
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editors);
      editor.config.onchange = (html) => {
        this.ruleForm.content = html;
      };
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      // editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;
      editor.config.pasteFilterStyle = false;
      editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };
      editor.config.pasteTextHandle = function (content) {
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
        // return content + '<p>在粘贴内容后面追加一行</p>'
        if (content == "" && !content) return "";
        let input = content;
        // 1. remove line breaks / Mso classes
        var stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
        var output = input.replace(stringStripper, " ");
        // 2. strip Word generated HTML comments
        var commentSripper = new RegExp("<!--(.*?)-->", "g");
        var output = output.replace(commentSripper, "");
        var tagStripper = new RegExp(
          "<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>",
          "gi"
        );
        // 3. remove tags leave content if any
        output = output.replace(tagStripper, "");
        // 4. Remove everything in between and including tags '<style(.)style(.)>'
        var badTags = [
          "style",
          "script",
          "applet",
          "embed",
          "noframes",
          "noscript",
        ];

        for (var i = 0; i < badTags.length; i++) {
          tagStripper = new RegExp(
            "<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>",
            "gi"
          );
          output = output.replace(tagStripper, "");
        }
        // 5. remove attributes ' style="..."'
        var badAttributes = ["style", "start"];
        for (var i = 0; i < badAttributes.length; i++) {
          var attributeStripper = new RegExp(
            " " + badAttributes[i] + '="(.*?)"',
            "gi"
          );
          output = output.replace(attributeStripper, "");
        }
        console.log("output----", output);
        return output;
      };
      editor.create();
    },
    // 富文本2
    editorInit2() {
      editors = new E(this.$refs.editorsquestionTitle);

      editors.config.onchange = (html) => {
        this.ruleForm.questionTitle = html;
      };
      editors.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editors.config.uploadImgMaxSize = 1 * 1024 * 1024;
      // editors.config.pasteFilterStyle = "text";
      editors.config.pasteFilterStyle = false;
      editors.config.pasteIgnoreImg = true;
      editors.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };
      editors.config.pasteTextHandle = function (content) {
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
        // return content + '<p>在粘贴内容后面追加一行</p>'
        if (content == "" && !content) return "";
        let input = content;
        // 1. remove line breaks / Mso classes
        var stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
        var output = input.replace(stringStripper, " ");
        // 2. strip Word generated HTML comments
        var commentSripper = new RegExp("<!--(.*?)-->", "g");
        var output = output.replace(commentSripper, "");
        var tagStripper = new RegExp(
          "<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>",
          "gi"
        );
        // 3. remove tags leave content if any
        output = output.replace(tagStripper, "");
        // 4. Remove everything in between and including tags '<style(.)style(.)>'
        var badTags = [
          "style",
          "script",
          "applet",
          "embed",
          "noframes",
          "noscript",
        ];

        for (var i = 0; i < badTags.length; i++) {
          tagStripper = new RegExp(
            "<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>",
            "gi"
          );
          output = output.replace(tagStripper, "");
        }
        // 5. remove attributes ' style="..."'
        var badAttributes = ["style", "start"];
        for (var i = 0; i < badAttributes.length; i++) {
          var attributeStripper = new RegExp(
            " " + badAttributes[i] + '="(.*?)"',
            "gi"
          );
          output = output.replace(attributeStripper, "");
        }
        console.log("output----", output);
        return output;
      };
      editors.create();
    },
    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      }
    },
    addOptions() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (editor.txt.text() == "") {
            this.showtitle = true;
            return false;
          } else if (editors.txt.html() == "") {
            this.showquestionTitle = true;
            return false;
          } else {
            this.showtitle = false;
            this.showquestionTitle = false;
            const that = this;
            let parmar = {};
            let qbQuestion = {
              questionBankId: that.questionBankId,
              questionOrigin: that.form.questionOrigin,
              questionTitle: editors.txt.html(),
              questionType: that.form.questionType,
              questionAnswer:
                that.form.questionType == 1
                  ? that.form.questionAnswer2.join("")
                  : that.form.questionAnswer,
              questionAnalysis: editor.txt.html(),
            };
            // if(questionBankId: "1",)
            parmar.qbQuestion = qbQuestion;
            let qbQuestionOptions = [];
            if (that.form.questionType == 0) {
              for (var i = 0; i < that.form.optionList.length; i++) {
                qbQuestionOptions.push({
                  optionContent: that.form.optionList[i].mins,
                  optionSpecialContent: that.form.optionList[i].imgKey,
                  optionIsCorrect:
                    that.form.questionAnswer == this.letterArr[i]
                      ? true
                      : false,
                });
              }
              parmar.qbQuestionOptions = qbQuestionOptions;
            } else if (that.form.questionType == 1) {
              for (var i = 0; i < that.form.optionListall.length; i++) {
                qbQuestionOptions.push({
                  optionContent: that.form.optionListall[i].optionContent,
                  optionSpecialContent:
                    that.form.optionListall[i].optionSpecialContentKey,
                  optionIsCorrect: that.form.optionListall[i].optionIsCorrect
                    ? true
                    : false,
                });
              }
              parmar.qbQuestionOptions = qbQuestionOptions;
            }
            that
              .$post("/qb/question/insert", parmar, 3000, true, 2)
              .then((ret) => {
                if (ret.status == "0") {
                  this.$message({
                    message: ret.message,
                    type: "success",
                  });
                }
                that.centerDialogVisible = false;
                that.empty();
                that.$emit("getnewdatap");
                that.$emit("subShows", true);
                if (ret.status == "-1") {
                  this.$message.error(ret.message);
                }
              })
              .catch((err) => {
                return;
              });
          }
        }
      });
    },
    delOpenList(index) {
      if (this.form.questionType == 0) {
        this.form.optionList.splice(index, 1);
      } else if (this.form.questionType == 1) {
        this.form.optionListall.splice(index, 1);
      }
    },
    addFromOpenList() {
      let data = {
        mins: "",
        img: "",
      };
      let data2 = {
        optionContent: "",
        optionSpecialContent: "",
        optionIsCorrect: false,
        optionSpecialContentKey: "",
      };
      if (this.form.questionType == 0) {
        this.form.optionList.push(data);
      } else if (this.form.questionType == 1) {
        this.form.optionListall.push(data2);
      }

      this.$forceUpdate();
    },
    docancle() {
      this.centerDialogVisible = false;
      this.empty();
    },
    empty() {
      this.form = {
        questionType: 0,
        optionList: [
          { mins: "", img: "" },
          { mins: "", img: "" },
        ],
        optionListall: [
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
            optionSpecialContentKey: "",
          },
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
            optionSpecialContentKey: "",
          },
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
            optionSpecialContentKey: "",
          },
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
            optionSpecialContentKey: "",
          },
        ],
        questionAnswer: "",
        questionAnswer2: [],
        questionOrigin: "10",
      };
      this.$refs["form"].resetFields();
      editor.txt.clear();
      editors.txt.clear();
    },
    closeDialog() {
      this.centerDialogVisible = false;
      this.empty();
    },
    formTypeChange(value) {
      this.$refs["form"].resetFields();
      editor.txt.clear();
      editors.txt.clear();
      this.form = {
        questionType: value,
        optionList: [
          { mins: "", img: "" },
          { mins: "", img: "" },
        ],
        optionListall: [
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
          {
            optionContent: "",
            optionSpecialContent: "",
            optionIsCorrect: false,
          },
        ],
        questionAnswer2: [],
        questionAnswer: "",
        questionScore: "1",
      };
    },
    //图片上传
    handleAvatarSuccess2(res, fiie, index) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "CT_QUESTION_BANK");
      formData.append("isPublic ", true);
      const that = this;
      that
        .$Postformat("/sys/file/upload", formData, 3000, true, 6)
        .then((result) => {
          that.form.optionList[index].imgKey = result.data.fileKey || "";
          that.form.optionList[index].img = result.data.fileUrl || "";
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    //图片上传
    handleAvatarSuccess3(res, fiie, index) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      const that = this;
      that
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          that.form.optionListall[index].optionSpecialContentKey =
            result.data.fileKey || "";
          that.form.optionListall[index].optionSpecialContent =
            result.data.fileURL || "";
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
  },
};
</script>
<style lang="less">
.el-input-number {
  .el-input__inner {
    line-height: 40px;
    height: 40px;
  }
}
.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
.el-form-questionType/deep/.el-form-item__content {
  margin-left: 0 !important;
}
.w-e-text-container {
  height: 250px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
}
</style>
<style lang="less" scoped>
.el-form-questionType {
  display: flex;
}
.el-form-item__content {
  display: flex;
  align-items: center;
  .el-radio-group .el-form-item /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
  }
  .checkbox /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
    .el-checkbox {
      display: flex;
      align-items: center;
      .el-checkbox__label {
        display: flex;
        align-items: center;
        .el-input {
          margin-left: 1.5rem;
        }
      }
    }
  }
}
.delet {
  margin-left: 0.5rem;
}
.el-form-item__content .img-el-upload /deep/.el-upload {
  margin-left: 15px;
  width: 30px;
  height: 30px !important;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.el-form-item__content {
    margin-left: 0;
}
</style>
